<template>

  <!-- Contributor View Panel -->
  <div id="contributor-view-panel">

    <!-- ArticleListing -->
    <StaffArticles  />

  </div>
</template>

<script>

// Components
import StaffArticles from '@/components/Staff/StaffArticles'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('staff')

export default {
  name: 'StaffView',
  // -------------
  // Components ==
  // -------------
  components: {
    StaffArticles
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const slug = to.params.slug
      if (slug !== from.params.slug) {
        vm.fetchArticles({ slug })
      }
    })
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'fetchArticles'
    ])
  }
}
</script>
