<template>
  <ArticleTable
    heading="Articles"
    :articles="articles"
    :hide="['byline']"
    date-label="Published"
    date-field="published_at"
    @paginate="handlePaginate"
  >
    <!-- Search Bar -->
    <template #actions>
      <j-search
        class="staff-article-search"
        @search="handleSearch"
      />
    </template>
  </ArticleTable>
</template>

<script>

// Components
import ArticleTable from '@/components/widgets/articles/ArticleTable'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('staff')

export default {
  name: 'StaffArticles',
  // -------------
  // Components ==
  // -------------
  components: {
    ArticleTable
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'articles'
    ]),
    slug() {
      return this.$route.params.slug || this.$user.slug
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'fetchArticles'
    ]),
    handleSearch (query) {
      this.fetchArticles({
        slug: this.slug,
        query
      })
    },
    handlePaginate(page) {
      this.fetchArticles({
        slug: this.slug,
        query: this.articles.get('query'),
        page
      })
    }
  }
}
</script>
